import { Component, Vue } from "vue-property-decorator";

import { STHeader, STFooter } from "@/layout";
import { ClassModel } from "@/models";
import { StCommon } from "../Common";

type response = { [key: string]: unknown };

@Component<ApStudentClassIndexController>({
    components: {
        STHeader, STFooter
    }
})
export default class ApStudentClassIndexController extends Vue {
    private classList: object = {};
    private token = window.localStorage.getItem('studentToken') as string;
    private stid = window.localStorage.getItem('student_stid') as string;

    public async created() {
        const item: { [key: string]: string } = {
            stid: this.stid,
            token: this.token,
        }
        const data = await ClassModel.stGetList(item) as response;
        StCommon.checkToken(data.ERR_CODE as number);
        this.classList = data.list as object;
    }
}